/**
* ============================
*  27.0 Team Area
* ============================
*/
.team-style {
  overflow: hidden;
  margin-bottom: $margin-default;
  .team-img {
  }
  .team-content {
    transition: all 0.5s ease 0s;
    background-color: $bg-color3;
    position: relative;
    bottom: -40px;
    .team-title {
      h4 {
        margin-bottom: $margin-small;
      }
      p {
        margin: 0;
      }
      padding: $margin-default;
    }
    .team-socials {
      margin-bottom: 0;
      li {
        width: 19%;
        text-align: center;
        background-color: $font-color3;
        margin: 0;
        a {
          color: $font-color4;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
  &:hover {
    .team-content {
      position: relative;
      bottom: 0px;
    }
  }
}
