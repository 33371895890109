/**
* ============================
*  11.0 Topics Area
* ============================
*/
.topics-service {
  border: $border-solid $border-color;
  box-shadow: $box-shadow-default;
  margin-bottom: $margin-default;
  padding: $padding-small;
  transition: all 0.5s ease 0s;
  position: relative;
  z-index: 1;
  .topics-title {
    position: relative;
    margin-bottom: 40px;
    &:before {
      content: "";
      width: 30px;
      height: 2px;
      background-color: $font-color3;
      display: block;
      bottom: -14px;
      position: absolute;
    }
  }
  .topics-list {
    margin: 0;
    li {
      margin-bottom: 5px;
      position: relative;
      padding-left: 25px;
      &:before {
        content: "\f02d";
        font-family: "FontAwesome";
        color: #b8babb;
        position: relative;
        position: absolute;
        left: 0;
        margin-top: 2px;
      }
    }
  }
  &:hover {
    transform: matrix(1, 0, 0, 1, 0, -5);
    -ms-transform: matrix(1, 0, 0, 1, 0, -5);
    -webkit-transform: matrix(1, 0, 0, 1, 0, -5);
    box-shadow: $box-shadow;
    border-color: $border-color2;
    .topics-title {
      &:before {
        width: 60px;
      }
    }
  }
}

.topics-area2 {
  .topics-wrapper {
    z-index: 4;
    position: relative;
  }
  .topics-service {
    padding: 0;
    border: 0;
    &:before {
      display: none;
    }
    &:hover {
      transform: none;
    }
    .topics-title {
      margin-bottom: 20px;
      &:before {
        display: none;
      }
    }
    .topics-list {
      li {
        &:before {
          content: "\f101";
          font-size: 18px;
          transition: all 0.5s ease 0s;
        }
        &:hover:before {
          padding-left: 5px;
        }
      }
    }
  }
}

.topics-page-content .bminus-30 {
  padding-bottom: 0;
}
