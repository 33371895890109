/**
* ============================
*  28.0 Footer Area
* ============================
*/

.footer-widget {
  margin-bottom: $margin-default;
}
.footer-title {
  position: relative;
  margin-bottom: 45px;
}
.footer-title:before {
  content: "";
  width: 45px;
  height: 1px;
  background-color: $font-color2;
  position: absolute;
  bottom: -15px;
  left: 0;
}

.footer-copyright p {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 40px;
}

.footer-copyright p a {
  color: $hover-color;
}
ul#results-container {
  margin: 0;
  list-style: none;
}
.modal-body {
  padding: 0.5rem;
}
.modal-content {
  border-radius: 0;
}
.revert-color .bg-color3 {
  background: #fff;
}

.revert-color .bg-color1 {
  background: #f2f6fb;
}
.item-id.active {
  opacity: 0;
  height: 0;
  transation: all 0.4s linear 0s;
}

.post-image {
  float: left;
  width: 122px;
  margin-right: 8px;
  a {
    display: block;
  }
}
.search-content {
  float: left;
  width: calc(100% - 130px);
  h3 {
    margin-bottom: 3px;
    a {
      color: #333;
      display: block;
      font-weight: 700;
      font-size: 18px;
      margin-top: 0px;
      display: table;
    }
  }
  p {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.8;
  }
}
div#results-container {
  float: left;
  width: 100%;
  background: #ffeded;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 5px;
}
.search-results {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding: 15px;
}
select:hover {
  cursor: pointer !important;
}

.topics-service-area {
  column-count: 3;
  column-gap: 30px;
  height: calc(100% - 2px);
  @include desktop {
    column-count: 2;
  }
  @include tablet {
    column-count: 1;
  }
  @include mobile {
    column-count: 1;
  }
  article {
    height: 100%;
    break-after: avoid;
    break-before: avoid;
    column-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    overflow: visible;
  }
}

.doc-sidebar-menu {
  width: 280px;
  ul {
    list-style: none;
    padding: 0;
    display: block;
  }
  li a {
    border-bottom: 1px solid darken($bg-color3, 10%);
    display: block;
    padding: 5px 15px;
    background: $bg-color3;
    line-height: 2;
  }
}
.tutorial {
  .sidebar {
    display: none;
    padding: 0;
    position: absolute;
    top: 0;
    width: 280px;
  }
  h2 {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .tutorial-content {
    margin-left: 300px;
  }
  .tutorial .sidebar {
    display: block;
  }
}
h2 a.header-link {
  text-decoration: none;
  color: #ccc;
  opacity: 0;
  transition: opacity ease 0.2s, color ease 0.2s;
  vertical-align: bottom;
  margin: 0 0 0 5px;
  &:hover {
    color: #4a4a4a;
    opacity: 0.4;
    transition: opacity ease 0.2s, color ease 0.2s;
  }
}
.doc-content a {
  color: $hover-color;
}
div#table-of-contents {
  background: $bg-color3;
}
div#modal {
  background: #fff;
}

.custom-links,
.doc-right-sidebar {
  position: sticky;
  top: 30px;
}

.custom-links {
  background: $bg-color3;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 30px;

  ul {
    border-left: 1px solid #hover-color;
    margin-top: 18px;

    .display-dropdown {
      .sub-dropdown {
        display: block;
      }

      a {
        .fa {
          transform: rotate(90deg);
        }
      }
    }

    li {
      position: relative;
      ul.sub-dropdown {
        padding: 0px;
        margin: 0px;
        list-style: none;
        margin-left: 15px;
        font-size: 14px;
        display: none;
      }
      i {
        text-align: center;
        position: absolute;
        right: -10px;
        top: 5px;
        border: 1px solid #ddd;
        width: 25px;
        display: inline-block;
        height: 25px;
        line-height: 1.4;
        cursor: pointer;
        border-radius: 50%;
        color: $hover-color;
        transition: all ease 0.2s;
        &:hover {
          background: $hover-color;
          border-color: $hover-color;
          color: $font-color4;
          transition: all ease 0.2s;
        }
      }

      a {
        line-height: 1.8;
        margin-left: -1px;
        display: block;
        padding-left: 15px;
        line-height: 1.7;
        padding-top: 6px;
        padding-bottom: 6px;

        i.fa.fa-angle-right {
        }
        &:hover {
          border-left: 1px solid #0d65b0;
        }
      }
    }
  }
}

.tutorial-content.mx-auto {
  margin: 0px;
}
