/**
* ============================
*  17.0 Help Area
* ============================
*/

.help-section {
  @include tablet {
    margin-bottom: $margin-small;
    text-align: center;
  }
}
.help-btn {
  @include tablet {
    text-align: center !important;
    margin-bottom: 5px;
  }
}
