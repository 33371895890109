/**
* ============================
*  13.0 Pupolar Article Area
* ============================
*/
.pupolar-article {
  border: $border-solid $border-color;
  padding: $padding-small;
  margin-bottom: $margin-default;
  box-shadow: $box-shadow-default;
  transition: all 0.5s ease 0s;

  .popular-article-top {
    border-bottom: $border-solid $border-color;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: table;
    width: 100%;
    .popular-top-logo {
      width: 44px;
      float: left;
      .popular-logo-icon {
        padding-right: 15px;
        border-right: $border-solid $bg-color2;
      }
    }
  }
  .popular-top-title {
    width: calc(100% - 44px);
    padding-left: 15px;
    float: left;
    h5 {
      margin: 0;
      margin-top: 4px;
    }
  }
  p {
    margin-bottom: $margin-default;
  }
  .popular-article-list {
    margin: 0;
    li {
      margin-bottom: 5px;
      position: relative;
      padding-left: 25px;
      &:before {
        content: "\f09e";
        font-family: "FontAwesome";
        color: $font-color3;
        position: relative;
        position: absolute;
        left: 0;
        margin-top: 0px;
        font-size: 16px;
      }
    }
  }

  &:hover {
    box-shadow: $box-shadow;
    transform: matrix(1, 0, 0, 1, 0, -5);
    -ms-transform: matrix(1, 0, 0, 1, 0, -5);
    -webkit-: matrix(1, 0, 0, 1, 0, -5);
    .popular-article-top {
      border-bottom-color: $border-color2;
    }
  }
}
.pupolar-item {
  border: $border-solid $border-color;
  padding: $padding-small;
  margin-bottom: $margin-default;
  box-shadow: $box-shadow-default;
  transition: all 0.5s ease 0s;

  .popular-item-top {
    border-bottom: $border-solid $border-color;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: table;
    width: 100%;
    .popular-top-logo {
      width: 44px;
      float: left;
      .popular-logo-icon {
        padding-right: 15px;
        border-right: $border-solid $bg-color2;
      }
    }
  }
  .popular-top-title {
    width: calc(100% - 44px);
    padding-left: 15px;
    float: left;
    h5 {
      margin: 0;
      margin-top: 4px;
    }
  }
  p {
    margin-bottom: $margin-default;
  }
  .popular-item-list {
    margin: 0;
    li {
      margin-bottom: 5px;
      position: relative;
      padding-left: 25px;
      &:before {
        content: "\f0e7";
        font-family: "FontAwesome";
        color: $font-color3;
        position: relative;
        position: absolute;
        left: 0;
        margin-top: 0;
        font-size: 16px;
      }
    }
  }

  &:hover {
    box-shadow: $box-shadow;
    transform: matrix(1, 0, 0, 1, 0, -5);
    -ms-transform: matrix(1, 0, 0, 1, 0, -5);
    -webkit-: matrix(1, 0, 0, 1, 0, -5);
    .popular-article-top {
      border-bottom-color: $border-color2;
    }
  }
}
.pupolar-article-area2 {
  .pupolar-article {
    padding: 0;
    .popular-article-top {
      padding: 20px 30px;
      @include background-opacity($bg-color2, 0.1);
    }
    .popular-article-list {
      margin: $margin-default;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
