/**
* ============================
*  1.0 Variable
* ============================
*/

// -------------------------------------------
/* Color */
// General font colours
$font-color1: #404040;
$font-color2: #000000;
$font-color3: #317bb4;
$font-color4: #ffffff;
$font-color5: #333333;

$font-color-default: $font-color1;

// Link colours
$link-color: #317bb4;
$hover-color: #12466b;
$heading-link-color: $font-color2;
$heading-hover-color: $hover-color;

$placeholder-color: #777777;

// Background colours
$bg-color1: #ffffff;
$bg-color2: #0d65b0;
$bg-color3: #f2f6fb;

$bg-color-default: $bg-color1;

// Button colours
$button-bg: #ffffff;
$button-bg2: #0d65b0;

// Heading colurs
$heading-color-default: $font-color3;
$font-color-h1: $heading-color-default;
$font-color-h2: $heading-color-default;
$font-color-h3: $heading-color-default;
$font-color-h4: $heading-color-default;
$font-color-h5: $heading-color-default;
$font-color-h6: $heading-color-default;

// -------------------------------------------
/* Typography */
$font-family-default: "Open Sans", sans-serif;

$font-size-default: 16px;
$font-size-thin: 12px;
$line-height-default: 30px;
$line-height-thin: 22px;

$font-weight-default: 400;
$font-weight-thin: 300;
$font-weight-medi: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

$font-size-h1: floor(($font-size-default * 1.78));
$font-size-h2: floor(($font-size-default * 1.78));
$font-size-h3: ceil(($font-size-default * 1.22));
$font-size-h4: ceil(($font-size-default * 1.3));
$font-size-h5: ceil(($font-size-default * 1.2));
$font-size-h6: ceil(($font-size-default * 1));

// -------------------------------------------
/* Border */
$border-color2: rgba(33, 108, 188, 0.1);
$border-color: rgba(0, 1, 1, 0.01);

$border-solid: 1px solid;

$border-radius: 4px;
$border-radius-round: 50px;

// -------------------------------------------
/* Component */
$overlay-color: rgba(33, 108, 188, 0.4);

$padding-small: 15px;
$padding-medium: 30px;
$padding-large: 60px;
$padding-default: $padding-medium;

$margin-small: 15px;
$margin-medium: 30px;
$margin-large: 60px;
$margin-default: $margin-medium;

$box-shadow-default: 0 0 20px 5px rgba(33, 107, 188, 0.01);
$box-shadow: 0 0 20px 5px rgba(33, 107, 188, 0.03);
