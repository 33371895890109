/**
* ============================
*  20.0 Privacy Policy Area
* ============================
*/
.privacy-policy-box {
  display: table;
  width: 100%;
  margin-bottom: $margin-default;
  .privacy-policy-icon {
    width: 60px;
    height: 60px;
    float: left;
    background-color: $bg-color-default;
    border: $border-solid $border-color;
    border-radius: 50px;
    text-align: center;
  }
  .privacy-policy-content {
    width: calc(100% - 60px);
    padding-left: $margin-default;
    float: left;
    h4,
    h6 {
      font-weight: $font-weight-semi;
    }
    h6 {
      text-decoration: underline;
    }
    p {
      margin-bottom: $margin-default;
    }
    .privacy-policy-list {
      li {
        &:before {
          content: "\f111";
          font-size: 13px;
          font-family: "fontawesome";
          margin-right: 10px;
          color: $font-color3;
        }
      }
    }
  }
}
