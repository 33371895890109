/**
* ============================
*  25.0 Timeline Area
* ============================
*/
.timeline ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    background: $bg-color3;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: inherit;
    }
    .timeline-content {
      position: relative;
      border: $border-solid $border-color;
      top: 0;
      min-width: 425px;
      padding: $margin-small $margin-default;
      background: $bg-color3;
      &::before {
        content: "";
        position: absolute;
        top: 7px;
        width: 0;
        height: 0;
        border-style: solid;
      }
      &:hover {
        box-shadow: $box-shadow;
      }
    }
    &:nth-child(even) .timeline-content {
      left: 35px;
      &::before {
        left: -15px;
        border-width: 15px 16px 15px 0;
        border-color: transparent $bg-color3 transparent transparent;
      }
    }
    &:nth-child(odd) .timeline-content {
      left: -470px;
      &::before {
        right: -15px;
        border-width: 15px 0 15px 16px;
        border-color: transparent transparent transparent $bg-color3;
      }
    }
  }
  h4 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
}

.timeline ul li {
  &::after {
    transition: background 0.5s ease-in-out;
  }
  &.in-view::after {
    background: $bg-color3;
  }
  .timeline-content {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  &:nth-child(even) .timeline-content {
    transform: translate3d(200px, 0, 0);

    margin-right: $margin-default;
  }
  &:nth-child(odd) .timeline-content {
    transform: translate3d(-200px, 0, 0);

    margin-left: $margin-small;
  }
  &.in-view .timeline-content {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (min-width: 1200px) {
  .timeline ul li {
    .timeline-content {
      min-width: 525px !important;
    }
    &:nth-child(even) .timeline-content {
      left: 35px;
      /*525px+45-6*/
    }
    &:nth-child(odd) .timeline-content {
      left: -570px;
      /*525px+45-6*/
    }
  }
}

@media screen and (max-width: 991px) {
  .timeline ul li {
    .timeline-content {
      min-width: 300px !important;
    }
    &:nth-child(even) .timeline-content {
      left: 40px;
      top: -8px;
      /*350px+45-6*/
    }
    &:nth-child(odd) .timeline-content {
      left: -350px;
      top: -8px;
      /*350px+45-6*/
    }
  }
}

@media screen and (max-width: 767px) {
  .timeline ul li {
    margin-left: 1px;
    padding-top: 0;
    padding-bottom: 15px;
    background: transparent;
    width: 100%;
    .timeline-content {
      width: 100%;
    }
    &:nth-child(even) .timeline-content {
      left: 0;
      margin: 0;
      overflow: hidden;
      padding: 15px;
    }
    &:nth-child(odd) .timeline-content {
      left: 0;
      margin: 0;
      overflow: hidden;
      padding: 15px;
      &::before {
        display: none;
      }
    }
    &:after {
      display: none;
    }
  }
}
