/**
* ============================
*  18.0  Form Area
* ============================
*/
.sub-title {
  margin-bottom: $margin-default;
}
.form-action {
  border: 0;
  width: 100%;
  margin-bottom: $margin-default;
  .form-group {
    margin-bottom: $margin-small;
    .form-control {
      padding: 10px 15px;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      width: 100%;
      background-color: $bg-color-default;
      color: $placeholder-color;
      font-size: $font-size-default;
      line-height: $line-height-default;
      &:focus {
        border: $border-solid $hover-color;
      }
    }
    .form-check-input {
      margin-top: 8px;
    }
    button[type="submit"] {
      position: relative;
    }
    .input-label {
      margin-top: 5px;
      display: inline-block;
      font-size: 14px;
      color: $placeholder-color;
    }
  }
  .file-upload {
    position: relative;
    width: 100%;
    height: 45px;
    border: $border-solid $border-color;
    border-radius: $border-radius;
    &:after {
      content: attr(data-text);
      font-size: $font-size-default;
      line-height: $line-height-default;
      font-weight: $font-weight-default;
      color: $placeholder-color;
      position: absolute;
      top: 0;
      left: 0;
      background: $bg-color-default;
      padding: 6px 15px;
      display: block;
      width: calc(100% - 40px);
      pointer-events: none;
      z-index: 20;
      border-radius: $border-radius;
    }

    &:before {
      content: "\f0c1";
      font-family: "FontAwesome";
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      background: $bg-color-default;
      color: $font-color3;
      z-index: 25;
      font-size: $font-size-default;
      line-height: 40px;
      padding: 0 15px;
      text-transform: uppercase;
      pointer-events: none;
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
      height: 45px;
      margin: 0;
      padding: 0;
      display: block;
      cursor: pointer;
      width: 100%;
    }
  }
}

.form-socials {
  margin: 0;
  li {
    a {
      width: 35px;
      height: 35px;
      display: block;
      border-radius: 50px;
      text-align: center;
      color: #fff;
      padding: 1px 0px;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.contact-map {
  margin-bottom: $margin-default;
}
