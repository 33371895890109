/**
* ============================
*  9.0 Page Title Area
* ============================
*/
1140 .page-title-wrapper {
  //max-width: 900px;
  margin: auto;
  padding-top: 150px;
  padding-bottom: 100px;
  @include desktop {
    //max-width: 700px;
  }
  @include tablet {
    //max-width: 750px;
    padding-top: 150px;
    padding-bottom: 100px;
  }
}
.page-title {
  position: relative;
  z-index: 22;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
.page-title-meta {
  margin: 0;
  margin-top: $margin-small;
  li {
    padding-right: 10px;
    img {
      width: 16px;
      margin-right: 10px;
      position: relative;
      bottom: 2px;
    }
  }
}
