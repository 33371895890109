/**
* ============================
*  24.0 Layout Area
* ============================
*/
.layout-breadcrumb {
  .breadcrumb {
    padding: unset;
    background-color: unset;
    border-radius: unset;
  }
}
.unorder-list {
  list-style: none;
  margin-bottom: $margin-default;
  li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 20px;
    &:before {
      content: "\f111";
      font-family: "FontAwesome";
      color: $font-color3;
      position: relative;
      position: absolute;
      left: 0;
      margin-top: 2px;
      font-size: 11px;
    }
  }
}
.order-list {
  margin-bottom: $margin-default;
  list-style-position: inside;
  counter-reset: item;
  list-style-type: decimal-leading-zero;
  li {
    a {
      color: $font-color3;
    }
  }
}
.layout-wrapper p {
  margin-bottom: $margin-default;
}
.sidebar-menu {
  padding: $margin-small;
  background-color: $bg-color3;
  @include tablet {
    margin-bottom: $margin-default;
  }
  button {
    background-color: transparent;
    width: 100%;
    text-align: left;
    position: relative;
  }
  .sub-menu {
    padding-left: 30px;
    margin: 0;
  }
  [aria-expanded*="false"]:after {
    content: "\f105";
    font-family: "fontawesome";
    color: $font-color3;
    position: absolute;
    right: 0;
  }
  [aria-expanded*="true"]:after {
    content: "\f107";
    font-family: "fontawesome";
    color: $font-color3;
    position: absolute;
    right: 0;
  }
}
.paper-details {
  p {
    margin-bottom: 0;
  }
  li {
    line-height: 24px;
  }
  h3 {
    margin-top: 0.5em;
    margin-bottom: 0;
  }
}
