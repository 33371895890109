/**
* ============================
*  26.0 Headline Area
* ============================
*/
.headline-wrapper {
  position: relative;
  bottom: 100px;
}
.headline-box {
  box-shadow: $box-shadow;
  border: $border-solid $border-color;
  position: relative;
  text-align: center;
  padding: $padding-small;
  z-index: 44;
  transition: all 0.5s ease 0s;
  background-color: $bg-color-default;
  @include desktop {
    margin-bottom: $margin-default;
  }
  @include tablet {
    margin-bottom: $margin-default;
  }
  .headline-logo {
    margin-bottom: $margin-default;
    img {
      max-height: 64px;
    }
  }
  .headline-content {
    h4 {
      margin-bottom: $margin-small;
    }
    p {
      margin-bottom: $margin-default;
    }
    .btn {
    }
  }
  &:hover {
    transform: matrix(1, 0, 0, 1, 0, -5);
    -ms-transform: matrix(1, 0, 0, 1, 0, -5);
    -webkit-transform: matrix(1, 0, 0, 1, 0, -5);
    box-shadow: $box-shadow;
    border-color: $border-color2;
  }
}
