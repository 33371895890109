/**
* ============================
*  7.0 Header Area
* ============================
*/

.header-area {
  padding-top: $padding-small;
  @include mobile {
    padding-top: 10px;
  }
}
.header-navigation {
  position: relative;
  z-index: 2222;
  display: table;
  width: 100%;
  .navigation-box {
    .navigation-navbar {
      margin: 0 auto;
      .menu-collapser {
        background: transparent;
        color: transparent;
        .collapse-button {
          background: transparent;
          position: absolute;
          top: 32px;
          right: 0px;
          @include mobile {
            top: 30px;
          }
          .icon-bar {
            width: 25px;
            margin: 5px 0;
            background-color: $font-color3;
          }
        }
      }
    }
    .navigation-left {
      text-align: center;
    }
    .navigation-right {
      display: flex;
    }
    .header-logo {
      margin: 10px 0;
      display: block;
      @include desktop {
        height: 50px;
      }
      @include mobile {
        margin: 8px 0;
        width: 110px;
      }
    }
    #navigation-menu {
      @include desktop {
        box-shadow: $box-shadow;
        position: absolute;
        left: 0;
        right: 0;
        background: $bg-color-default;
        padding: 0 $margin-small;
        top: 83px;
        @include mobile {
          top: 63px;
        }
      }
      @include tablet {
        box-shadow: $box-shadow;
        position: absolute;
        left: 0;
        right: 0;
        background: $bg-color-default;
        padding: 0 $margin-small;
        top: 143px;
        @include mobile {
          top: 95px;
        }
      }
      li {
        border: 0;
        background-color: transparent;
        .nav-search {
          padding-right: 0;
          cursor: pointer;
          img {
            width: 20px;
            top: -2px;
            position: relative;
          }
          @include desktop {
            display: none;
          }
          @include tablet {
            display: none;
          }
        }
        a {
          font-size: $font-size-default;
          line-height: $line-height-default;
          padding: $margin-small;
          color: $font-color3;
          font-weight: $font-weight-default;
          @include desktop {
            padding: 10px 15px 10px 15px;
            color: $font-color-default;
          }
          @include tablet {
            padding: 10px 15px 10px 15px;
            color: $font-color-default;
          }
          &:hover,
          &:focus {
            background-color: transparent;
            opacity: 0.9;
          }
        }
        &.has-submenu {
          a {
            padding: 15px 30px 15px 15px;
            @include desktop {
              padding: 10px 15px 10px 15px;
            }
            @include tablet {
              padding: 10px 15px 10px 15px;
            }
          }
          ul {
            background-color: $bg-color-default;
            min-width: 200px;
            position: absolute;
            &:before {
              content: "";
              position: absolute;
              left: 24px;
              top: -10px;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 20px solid $bg-color-default;

              transition: all 0.5s ease 0s;
            }
            @include desktop {
              position: relative;
              left: 0;
              background-color: $bg-color-default;
            }
            @include tablet {
              position: relative;
              left: 0;
              background-color: $bg-color-default;
            }
            li {
              transition: all 0.5s ease 0s;
              float: none;
              display: block;
              &:last-child a {
                border-bottom: 0;
              }
              a {
                padding: 10px $margin-small;
                border-bottom: $border-solid $border-color;
                color: $font-color-default;
              }
              &:hover {
                border-left: 4px solid $border-color;
              }
              @include desktop {
                &:hover {
                  border: 0;
                }
              }
              @include tablet {
                &:hover {
                  border: 0;
                }
              }
            }
          }
        }
        .sub-toggle {
          background: transparent;
          width: 20px;
          @include desktop {
            width: 100%;
            text-align: right;
          }
          @include tablet {
            width: 100%;
            text-align: right;
          }
          i {
            color: transparent;
            position: relative;
            &:before {
              content: "\f107";
              font-family: "FontAwesome";
              position: absolute;
              color: $font-color4;
              font-style: normal;
              @include desktop {
                color: $font-color-default;
              }
              @include tablet {
                color: $font-color-default;
              }
            }
          }
        }
      }
    }
  }
}

#modal {
  button[type="button"] {
    background: $bg-color-default;
    color: $font-color2;
  }
}
.modal-search-form {
  border: 1px solid #ddd;
  input[type="text"] {
    border-radius: 0;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background-color: $bg-color-default;
    border: 0;
    padding: $margin-small $margin-default;
  }
  button {
    border-radius: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border: 0;
    background-color: $bg-color-default;
    padding: $margin-small $margin-default;
    color: $font-color3;
    &:hover,
    &:focus,
    &:active {
      background-color: $bg-color-default !important;
      color: $font-color3 !important;
    }
  }
}

.tag-widget {
  margin: 0;
  a {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 30px;
    color: $font-color-default;
    font-weight: 400;
    background-color: #f2f6fb;
    border-radius: 0;
    padding: 8px 30px !important;
    display: inline-block;
    text-transform: capitalize;
    &:hover {
      color: #ffffff;
      background-color: $bg-color2;
    }
  }
}
