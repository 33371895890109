/**
* ============================
*  15.0 Feature Area
* ============================
*/
.feature-box {
  box-shadow: $box-shadow;
  padding: $padding-small;
  text-align: center;
  border: $border-solid transparent;
  margin-bottom: $margin-default;
  .feature-logo {
    position: relative;
    margin: auto;
    margin-bottom: $margin-small;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    //background-color:$bg-color2;

    transition: all 0.2s ease 0s;
    .feature-icon {
      img {
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        margin: auto;
        position: absolute;
      }
      i {
        font-size: 30px;
        color: $font-color3;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 30px;
        height: 30px;
      }
      a {
        &:focus-visible {
          i {
            border: 1px solid blue;
          }
        }
      }
      .feature-flag-logo {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        transition: all 0.2s ease 0s;
      }
    }
  }

  .feature-content {
    p {
      margin: 0;
    }
  }
  &:hover,
  .active {
    border: $border-solid $border-color;
    .feature-logo {
      transform: matrix(1.05, 0, 0, 1.05, 0, 0);
      -ms-transform: matrix(1.05, 0, 0, 1.05, 0, 0);
      -webkit-transform: matrix(1.05, 0, 0, 1.05, 0, 0);
    }
  }
}
