/**
* ============================
*  19.0 Changelog Area
* ============================
*/
.changelog-service {
  margin-top: $margin-default;
  margin-bottom: $margin-default;
  @include tablet {
    margin-bottom: $margin-default;
    margin-top: 0;
  }
  .changelog-tile {
    margin-bottom: $margin-small;
    h5 {
      margin-bottom: 5px;
      font-weight: $font-weight-semi;
    }
    p {
      margin: 0;
      .changelog-date {
        width: 18px;
        height: 16px;
        background-image: url(../images/changelog-date.png);
        background-position: center;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
  .changelog-list {
    margin: 0;
    li {
      .changelog-fix {
        width: 18px;
        height: 12px;
        background-image: url(../images/changelog-fix.png);
        background-position: center;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 11px;
      }
      .changelog-bug {
        width: 18px;
        height: 16px;
        background-image: url(../images/changelog-bug.png);
        background-position: center;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 9px;
      }
    }
  }
}
