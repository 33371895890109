/**
* ============================
*  21.0 Company Area
* ============================
*/

.company-slider {
  .company-logo {
    text-align: center;
  }
  .company-logo img {
    width: auto;
  }
}
