/**
* ============================
*  14.0 Testimonial Area
* ============================
*/
.testimonial-item {
  margin-bottom: $margin-default;
  margin-top: $margin-default;
  @include tablet {
    margin-bottom: $margin-small;
    margin-top: $margin-small;
  }
  .testimonial-author {
    margin-bottom: 25px;
    img {
      border-radius: 100%;
      width: auto;
    }
  }
  .testimonial-content {
    p {
      margin-bottom: 20px;
      margin-top: 10px
    }
  }
  .testimonial-rating {
    margin-bottom: 5px;
    i {
      color: $hover-color;
    }
  }
  .testimonial-name {
    margin-bottom: 5px;
  }
  .testimonial-subname {
    margin: 0;
    color: $placeholder-color;
    font-weight: 400;
  }
}
.testimonial-slider,
.testimonial-slider2 {
  .owl-nav {
    line-height: 14px;
    text-align: center;
    margin-top: $margin-default;
  }
  .owl-prev {
    margin-right: 20px;
    &:before {
      content: "";
      background-image: url(../images/testimonial-left.png);
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      background-repeat: no-repeat;
      width: 23px;
      height: 16px;
      display: block;
    }

    &:hover:before {
      filter: none;
    }
    span {
      display: none;
    }
  }
  .owl-next {
    &:before {
      content: "";
      content: "";
      background-image: url(../images/testimonial-right.png);
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      background-repeat: no-repeat;
      width: 23px;
      height: 16px;
      display: block;
    }

    &:hover:before {
      filter: none;
    }
    span {
      display: none;
    }
  }
}

.testimonial-feature-header {
  text-align: left;
}

.testimonial-quoting {
  text-align: right;
  font-size: 75%;
}

.testimonial-read-more, .testimonial-headline {
  font-style: italic;
  margin-bottom: 0;
}

.testimonial-read-more {
  text-align: right;
  font-size: 75%;
}