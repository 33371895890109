/**
* ============================
*  10.0 Service Area
* ============================
*/
.section-title {
  margin-bottom: 50px;
}
.white-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  ul,
  p,
  span,
  *,
  *::before,
  *::after {
    color: $font-color4 !important;
  }
  a:hover,
  a:focus {
    opacity: 0.8;
  }
}
.service-box {
  padding: $padding-small;
  border: $border-solid $border-color;
  box-shadow: $box-shadow-default;
  text-align: center;
  margin-bottom: $margin-default;
  transition: all 0.5s ease 0s;
  .service-logo {
    margin-bottom: 20px;
    .service-icon {
      img {
        transition: all 0.5s ease 0s;
      }
    }
  }
  .service-content {
    h4 {
      position: relative;
    }
    p {
      margin: 0;

      transition: all 0.5s ease 0s;
    }
  }
  &:hover {
    box-shadow: $box-shadow;
    border-color: $border-color2;
    .service-icon {
      img {
        transform: matrix(0.9, 0, 0, 0.9, 0, 0);
        -ms-transform: matrix(0.9, 0, 0, 0.9, 0, 0);
        -webkit-transform: matrix(0.9, 0, 0, 0.9, 0, 0);
      }
    }
  }
}
