/**
* ============================
*  8.0 Slider Area
* ============================
*/

#particles-js {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 5;
}
.slider-carousel {
  z-index: 22;
}
.slider-wrapper {
  padding-top: 50px;
  padding-bottom: 150px;
  @include tablet {
    padding-top: 25px;
    padding-bottom: 100px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
    background: $overlay-color;
  }
}
.full-padding {
  padding: 300px 0;
  @include tablet {
    padding: 200px 0;
  }
}
.slider-search-form {
  input[type="text"] {
    border-radius: 0;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border: 0;
    background-color: $bg-color-default;
    padding: $margin-small $margin-default;
  }
  button {
    border-radius: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border: 0;
    background-color: $bg-color-default;
    padding: $margin-small $margin-default;
    color: $font-color3;
    &:hover,
    &:focus,
    &:active {
      background-color: $bg-color-default !important;
      color: $font-color3 !important;
    }
  }
}
.slider-content {
  top: 30%;
  position: relative;
  z-index: 22;
  h1,
  h2 {
    font-weight: $font-weight-thin;
    color: $font-color4;
    //margin-bottom: $margin-large;
    span {
      font-weight: $font-weight-bold;
    }
  }
  h3,
  h4 {
    font-weight: $font-weight-thin;
    color: $font-color4;
    margin-bottom: $margin-default;
    span {
      font-weight: $font-weight-bold;
    }
  }
  .btn {
    padding: 15px 30px;
    background-color: $bg-color-default;
    color: $font-color2;
  }
  p {
    color: $font-color4;
  }
}
