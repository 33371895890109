/**
* ============================
*  25.0 Map Area
* ============================
*/
.headline-area {
  .headline-wrapper {
    .container {
      background-color: $font-color4;
      h2 {
        color: $font-color2;
        span {
          color: $font-color3;
          font-size: floor(($font-size-default * 2.56));
        }
      }
      #map {
        width: 90%;
        height: 50vh;
        margin: 0 auto;
      }
    }
  }
}

.map-area {
  .map-wrapper {
    #map {
      width: 75%;
      height: 50vh;
      margin: 0 auto;
    }
  }
}

.map-area,
.headline-area {
  .map-wrapper,
  .headline-wrapper {
    a.skip-main {
      left: -999px;
      position: absolute;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
      z-index: -999;
      &:focus,
      &:active {
        color: #fff;
        background-color: #000;
        left: auto;
        top: auto;
        width: 30%;
        height: auto;
        overflow: auto;
        margin: 10px 35%;
        padding: 5px;
        border-radius: 15px;
        border: 4px solid yellow;
        text-align: center;
        font-size: 1.2em;
        z-index: 999;
      }
    }
  }
}

.bighighlight {
  color: $font-color3;
}

a {
  &:focus-visible {
    outline: 1px solid blue;
  }
}

a.btn-style3 {
  &:focus-visible {
    background-color: blue;
    color: white !important;
  }
}
