/**
* ============================
*  12.0 Faq Area
* ============================
*/
.faq-service {
  box-shadow: $box-shadow;
  padding: $padding-small;
  margin-bottom: $margin-default;
  border: $border-solid $border-color;
  display: table;

  transition: all 0.5s ease 0s;
  width: 100%;
  .faq-logo {
    float: left;
    transition: all 0.5s ease 0s;
    .logo-text {
      font-size: 30px;
      line-height: 40px;
      font-weight: $font-weight-semi;
      color: $font-color4;
      text-align: center;
      padding: 15px 5px;
      background-color: $bg-color2;
      width: 70px;
      border-radius: 50%;

      height: 70px;
    }
    .logo-image {
      font-size: 30px;
      line-height: 40px;
      font-weight: $font-weight-semi;
      color: $font-color4;
      text-align: center;
      background: $bg-color3;
      width: 45px;
      height: auto;
      img {
        padding: 8px;
      }
    }
    @include mobile {
      display: none;
    }
  }
  .faq-content {
    float: right;
    width: calc(100% - 45px);
    padding-left: 20px;
    h4 {
    }
    p {
      margin: 0;
    }
    @include mobile {
      width: 100%;
      padding: 0;
    }
  }
}

.faq-accordion {
  margin-top: $margin-small;
  .card {
    border: 0;
    margin-bottom: 5px;
    .card-header {
      background-color: $bg-color2;
      button[type="button"] {
        color: $font-color4;
      }
    }
  }
}
