/**
* ============================
*  23.0 Sidebar Area
* ============================
*/

.sidebar-title {
  margin-bottom: $margin-small;
}

.search-form-widget {
  display: block;
  input[type="text"] {
    background-color: $bg-color3;
    border: 0;
    padding: 10px 20px;
    border-radius: 0;
  }
  button[type="submit"] {
    background-color: $bg-color2;
    font-size: 20px;
    line-height: 30px;
    color: $font-color4;
    padding: 10px 25px;
    border: 0;
    border-radius: 0;
    &:hover {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    }
  }
}

.category-widget {
  margin: 0;
  li {
    border: 0;
    border-radius: 0 !important;
    padding: 10px 0;
    border-bottom: $border-solid $border-color;
    margin: 0;
    position: relative;
    a {
      font-size: 16px;
      line-height: 28px;
      color: $font-color-default;
      font-weight: $font-weight-default;
    }
    &:hover a,
    &.list-group-active a {
      color: $font-color3;
    }
    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
    &:first-child {
      padding-top: 0;
    }
  }
}

.recent-widget {
  display: block;
  .recent-wrapper {
    display: table;
    width: 100%;
    margin-bottom: 20px;
    .recent-img {
      width: 85px;
      float: left;
      overflow: hidden;
      img {
        width: 100%;
        -webkit-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
      }
      &:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    .recent-details {
      width: calc(100% - 85px);
      padding-left: 12px;
      float: left;
      h6 {
        font-weight: $font-weight-default;
      }
    }
    .recent-meta {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      color: $placeholder-color;
    }
  }
}

.tag-widget {
  margin: 0;
  li {
    margin-bottom: 10px;
    a {
      font-size: $font-size-default;
      line-height: $line-height-default;
      color: $font-color-default;
      font-weight: $font-weight-default;
      background-color: $bg-color3;
      border-radius: 0;
      padding: 8px 30px;
      display: inline-block;
      &:hover {
        color: $font-color4;
        background-color: $bg-color2;
      }
    }
  }
}

.sidebar-widget {
  margin-bottom: $margin-large;
  @include desktop {
    margin-bottom: $margin-default;
  }
  @include tablet {
    margin-bottom: $margin-default;
  }
}
.error-page {
  img {
    margin-bottom: $margin-large;
  }
}

.sidebar-left {
  @include tablet {
    margin-bottom: $margin-default;
  }
}
.sidebar-right {
  @include tablet {
    margin-top: $margin-default;
  }
}
