/**
* ============================
*  16.0 Looking For Area
* ============================
*/
.looking-for-service {
  background-color: $bg-color-default;
  border: $border-solid $border-color;
  text-align: center;
  padding: 40px 30px;

  margin-bottom: $margin-default;
  transition: all 0.5s ease 0s;
  .looking-for-logo {
    margin-bottom: 25px;
    a {
      border-radius: 50px;
      background-color: $bg-color-default;
      width: 80px;
      height: 80px;

      border: $border-solid $border-color;
      display: block;
      margin: auto;
    }
    img {
      transition: all 0.5s ease 0s;
      padding: 13px 4px;
    }
  }
  .looking-for-content {
    h4 {
      margin-bottom: 20px;
    }
  }
  &:hover {
    background-color: $bg-color2;
    box-shadow: $box-shadow;
    .looking-for-logo {
      img {
        transform: matrix(0.9, 0, 0, 0.9, 0, 0);
        -ms-transform: matrix(0.9, 0, 0, 0.9, 0, 0);
        -webkit-transform: matrix(0.9, 0, 0, 0.9, 0, 0);
      }
    }
    h4 {
      a {
        color: $font-color4;
      }
    }
    p {
      color: $font-color4;
    }
    .btn {
      background-color: $bg-color-default;
      color: $hover-color;
    }
  }
}
.newsletter-form {
  max-width: 340px;
  margin: auto;
  margin-top: $margin-default;
  input[type="text"] {
    border-radius: 0;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    padding: 15px;
    padding-left: 30px;
    &:focus {
      border: $border-solid $hover-color;
    }
  }
  button[type="submit"] {
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 15px 15px;
    background-color: $button-bg2;
  }
}
