/**
* ============================
*  22.0 Blog Area
* ============================
*/

.blog-post {
  margin-bottom: $margin-default;
  .blog-img {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
    img {
      width: 100%;
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in;
    }
    &:hover img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  .blog-content {
    margin-bottom: 0;
    h4 {
      margin-bottom: $margin-small;
    }
    p {
      margin-bottom: $margin-small;
    }
  }
  .blog-meta {
    li {
      padding-right: 10px;
      border-right: $border-solid $border-color;
      img {
        width: 16px;
        margin-right: 10px;
        position: relative;
        bottom: 2px;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

.blog-spacing {
  margin-bottom: $margin-large;
  @include desktop {
    margin-bottom: $margin-default;
  }
  @include tablet {
    margin-bottom: $margin-default;
  }
}

.page-navigation .pagination {
  margin: 0;
  li {
    margin-right: 10px;
    a {
      font-size: $font-size-default;
      line-height: $line-height-default;
      color: $font-color-default;
      border-radius: 0 !important;
      border: $border-solid $border-color;
      padding: 5px 30px;
    }
    &:first-child,
    &:last-child {
      a {
        background-color: $bg-color2;
        color: $font-color4;
      }
    }
  }
}

.single-post {
  .single-img {
    position: relative;
    margin-bottom: 25px;
    img {
      width: 100%;
    }
  }
  .single-content {
    margin-bottom: $margin-default;
    p {
      margin-bottom: $margin-default;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .single-quote {
    position: relative;
    padding-left: $margin-small;
    margin-bottom: $margin-default;
    p {
      font-size: $font-size-default;
      line-height: $line-height-default;
      margin: 0;
    }
    &:before {
      content: "";
      width: 2px;
      height: 100%;
      background-color: $bg-color2;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      content: "";
      width: 24px;
      height: 25px;
      background-image: url(../images/post-qoute-icon.png);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 14px;
    }
  }
  .single-tags-share {
    display: table;
    width: 100%;
    background-color: $bg-color-default;
    margin-bottom: $margin-default;
    border-top: $border-solid $border-color;
    border-bottom: $border-solid $border-color;
    .single-tags {
      font-size: $font-size-default;
      line-height: $line-height-default;
      color: $font-color2;
      font-weight: 400;
      margin: 0;
      li:first-child {
        font-weight: 500;
      }
      a {
        color: $font-color-default;
        &:hover,
        &:focus {
          color: $hover-color;
        }
      }
      @include desktop {
        float: none !important;
        padding-bottom: 0;
        text-align: center;
      }
      @include tablet {
        float: none !important;
        padding-bottom: 0;
        text-align: center;
      }
    }
    .single-share {
      font-size: $font-size-default;
      line-height: $line-height-default;
      margin: 0;
      li:first-child {
        font-weight: 500;
        color: $font-color2;
      }
      a {
        color: $placeholder-color;
        &:hover,
        &:focus {
          color: $placeholder-color;
        }
      }
      @include desktop {
        text-align: center;
        float: none !important;
        padding-top: 0;
      }
      @include tablet {
        text-align: center;
        float: none !important;
        padding-top: 0;
      }
    }
  }
}

.single-admin {
  position: relative;
  display: table;
  width: 100%;
  margin-bottom: $margin-default;
  .admin-avatar {
    width: 130px;
    float: left;
    @include desktop {
      width: 100px;
    }
  }
  .admin-comment {
    width: calc(100% - 130px);
    float: left;
    padding-left: 25px;
    padding-top: 8px;
    p {
      margin-bottom: 20px;
    }
    h5 {
      margin: 0;
    }
    h6 {
      font-size: 14px;
      color: $placeholder-color;
      margin: 0;
    }
    @include desktop {
      width: calc(100% - 100px);
    }
  }
}

.page-pagination {
  .pagination {
    margin-bottom: 30px;
  }
  .next-btn,
  .prev-btn {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: $font-color-default;
    border-radius: 0;
    border: 0;
    padding: 0;
  }
  .next-btn:hover {
    color: $hover-color;
    background-color: transparent;
  }
  .prev-btn {
    &:hover {
      color: $hover-color;
      background-color: transparent;
    }
    i {
      margin-left: 10px;
    }
  }
  .next-btn i {
    margin-right: 5px;
  }
}
.single-comment {
  display: table;
  width: 100%;
  margin-bottom: 35px;
}

.comment-wrapper {
  padding-left: 50px;
}

.single-comment-img {
  width: 68px;
  float: left;
}

.single-comment-desc {
  width: calc(100% - 68px);
  float: left;
  padding-left: 15px;
  h6 {
    .single-priod {
      font-size: 14px;
      line-height: 30x;
      font-weight: 400;
      color: $placeholder-color;
      margin-left: 10px;
    }
  }
  p {
    margin-bottom: 8px;
  }
  .single-comment-btn {
    color: $hover-color;
    font-size: $font-size-default;
    line-height: $line-height-default;
  }
}
